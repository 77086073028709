<div class="header">
    <div class="container-fluid">
        <div class="d-flex justify-content-between">
            <div class="logo">
                <a routerLink="/">
                    <img [src]="env.logoUrl" [alt]="env.title">
                </a>
                <h3 *ngIf="env.instanceReception?.label"  class="mt-5 mb-0">{{env.instanceReception.label | translate}}</h3>
            </div>
            <div class="clock-container text-center">
                <div class="time h1">{{ clock | date: 'HH:mm' }}</div>
                <div class="date">{{ clock | date: 'dd.MM.yyyy' }}</div>
            </div>
        </div>
    </div>
</div>

<input
    #autoFocus
    autofocus
    type="text"
    class="hidden d-block"
    autocomplete="off"
    ngxBarCodePut
    [debounce]="300"
    (onDetected)="onQrRead($event)"
>

<router-outlet></router-outlet>

<div *ngIf="isCheckingIn" class="overlay">
    <div class="overlay-content">
        <span class="message fw-bold">
            {{ 'Checking-in, please wait' | translate }}
        </span>
        <div class="loading d-inline-flex">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
</div>

<div class="footer">
    <div class="lang">
        <ul class="">
            <li>
                <!-- Default dropstart button -->
                <div class="btn-group dropstart">
                    <button type="button" (click)="$event.stopPropagation();toggleLanguageMenu(!isLanguageMenuShown)" class="language-toggle">
                        <img *ngIf="!isLanguageMenuShown" src="/assets/img/globe-white.svg" class="btn-icon" alt="Select language">
                        <img *ngIf="isLanguageMenuShown" src="/assets/img/times-white.svg" class="btn-icon" alt="Close language selector">
                    </button>
                    <ul class="language-menu" [class.show]="isLanguageMenuShown">
                        <li>
                            <button type="button" (click)="useLanguage('de')" [class.is-active]="currentLang === 'de'">DE</button>
                        </li>
                        <li>
                            <button type="button" (click)="useLanguage('fr')" [class.is-active]="currentLang === 'fr'">FR</button>
                        </li>
                        <li>
                            <button type="button" (click)="useLanguage('en')" [class.is-active]="currentLang === 'en'">EN</button>
                        </li>
                        <li>
                            <button type="button" (click)="useLanguage('it')" [class.is-active]="currentLang === 'it'">IT</button>
                        </li>
                    </ul>
                </div>
            </li>
            <li>
                <button type="button" routerLink="/help" routerLinkActive="is-active">?</button>
            </li>
        </ul>
    </div>
</div>

<div *ngIf="!hasConnection" class="overlay">
    <div class="overlay-content">
        <h1>{{'Oops, we\'re offline' | translate}}</h1>
        <h5>
            {{'Seems like someone cut out Internet cable :(' | translate}}<br>
            {{'Hold on tight, Internet is on the way...' | translate}}
        </h5>
        <img src="assets/img/exclamation-circle.svg" alt="error" />
    </div>
</div>

<div *ngIf="isIdle" class="overlay">
    <div class="overlay-content">
        <h1>{{'Need help?' | translate}}</h1>

        <h1 class="my-5">{{idleCounter}}</h1>

        <h5>
            {{'Seems like you have been inactive for some time.' | translate}}<br>
            {{'If you are ok, just need more time, please click on the button bellow.' | translate}}
        </h5>

        <button class="btn btn-primary" (click)="closeIdleCounter()">{{'I\'m fine, close this' | translate}}</button>

    </div>
</div>
